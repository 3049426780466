import React from "react";

import useSmoothScrollWithOffset from "../useSmoothScrollWithOffset";

export const Courses = (props) => {
    useSmoothScrollWithOffset(80);
    return (
      <div id="courses">
        <h1>Coming Soon...</h1>
        </div>
    );
    }