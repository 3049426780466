import React from "react";

import useSmoothScrollWithOffset from "../useSmoothScrollWithOffset";

export const Internship = (props) => {
    useSmoothScrollWithOffset(80);
    return (
      <div id="internship">
        <h1>Coming Soon...</h1>
        </div>
    );
    }